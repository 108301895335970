import gql from 'graphql-tag';

export const CcpTransactionAccountIdQuery = gql`
	query CCPEntitlement($entitlementId: ID!) {
		ccp {
			entitlement(id: $entitlementId) {
				transactionAccountId
			}
		}
	}
`;
