import { token } from '@atlaskit/tokens';
import { P500, B500 } from '@atlaskit/theme/colors';

export const GRADIENT_CTA_BUTTON_DEFAULT_START = token('color.chart.purple.bolder', '#8270DB');
export const GRADIENT_CTA_BUTTON_DEFAULT_END = token('color.chart.blue.bolder', '#1D7AFC');
export const GRADIENT_CTA_BUTTON_HOVER_START = token(
	'color.chart.purple.bolder.hovered',
	'#6E5DC6',
);
export const GRADIENT_CTA_BUTTON_HOVER_END = token('color.chart.blue.bolder.hovered', '#0C66E4');
export const GRADIENT_CTA_BUTTON_ACTIVE_START = token('color.chart.purple.boldest', '#5E4DB2');
export const GRADIENT_CTA_BUTTON_ACTIVE_END = token('color.chart.blue.boldest', '#0055CC');

export const GRADIENT_BACKGROUND_SUBTLEST_START = token(
	'color.background.accent.blue.subtlest',
	'#E9F2FF',
);

export const GRADIENT_BACKGROUND_SUBTLEST_END = token(
	'color.background.accent.purple.subtlest',
	'#F3F0FF',
);

export const GRADIENT_CTA_BUTTON_INVERTED_BORDER_START = token('color.border.accent.purple', P500);

export const GRADIENT_CTA_BUTTON_INVERTED_BORDER_END = token('color.border.accent.blue', B500);

// (color.background.accent.purple.bolder.pressed, P900) from new color palette
export const GRADIENT_CTA_BUTTON_SELECTED_START = token(
	'color.background.discovery.bold.pressed',
	'#352C63',
);

// (color.background.accent.blue.bolder.pressed, B900) from new color palette
export const GRADIENT_CTA_BUTTON_SELECTED_END = token(
	'color.background.selected.bold.pressed',
	'#09326C',
);
