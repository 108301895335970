import type { ComponentType } from 'react';
import React, { useContext, createContext, useCallback, useMemo } from 'react';

import type { AnalyticsEventPayload, CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

export type AnalyticsProviderData = {
	customAttributes?: Record<string, any>;
};

export type AnalyticsProviderProps = React.PropsWithChildren<AnalyticsProviderData>;

const EditionsAnalyticsContext = createContext<AnalyticsProviderData | undefined>(undefined);

export type AnalyticsPayloadType =
	| 'sendScreenEvent'
	| 'sendUIEvent'
	| 'sendTrackEvent'
	| 'sendOperationalEvent';

export type AnalyticsContextProps = {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	wrapAnalyticsEventPayload: (payload: AnalyticsEventPayload) => AnalyticsEventPayload;
};

export const wrapWithEditionsAnalytics = (
	payload: AnalyticsEventPayload,
	data: AnalyticsProviderData | undefined,
) => ({
	...payload,
	data: {
		...payload.data,
		attributes: {
			...payload.data.attributes,
			...data?.customAttributes,
		},
	},
});

export const useEditionsAnalyticsContext = (): AnalyticsContextProps => {
	const internalData = useContext(EditionsAnalyticsContext);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const wrapAnalyticsEventPayload = useCallback(
		(data: AnalyticsEventPayload) => wrapWithEditionsAnalytics(data, internalData),
		[internalData],
	);

	const wrappedCreateAnalyticsEvent = useCallback(
		(initialPayload: AnalyticsEventPayload) =>
			createAnalyticsEvent(wrapAnalyticsEventPayload(initialPayload)),
		[createAnalyticsEvent, wrapAnalyticsEventPayload],
	);

	return {
		createAnalyticsEvent: wrappedCreateAnalyticsEvent,
		wrapAnalyticsEventPayload,
	};
};

const emptyAttributes = {};
export const AnalyticsProvider = ({
	children,
	customAttributes = emptyAttributes,
}: AnalyticsProviderProps) => {
	const editionsAnalyticsContextValue = useMemo(() => ({ customAttributes }), [customAttributes]);

	return (
		<EditionsAnalyticsContext.Provider value={editionsAnalyticsContextValue}>
			{children}
		</EditionsAnalyticsContext.Provider>
	);
};

export const withEditionsAnalyticsEvents = <T extends Record<string, any>>(
	Component: ComponentType<T & Partial<AnalyticsContextProps>>,
) => {
	const WrappedComponent = (props: T) => {
		const context = useEditionsAnalyticsContext();
		return <Component {...props} {...context} />;
	};

	return WrappedComponent;
};
