import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import { B200, B300, P200, P300 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkWrapper = styled.a({
	display: 'inline-flex',
	alignItems: 'center',
	cursor: 'pointer',
	background: `linear-gradient( 80deg, ${token(
		'color.chart.purple.bolder',
		P200,
	)}, ${token('color.chart.blue.bolder', B200)} )`,
	fontWeight: 700,
	fontSize: '14px',
	lineHeight: '20px',
	width: 'fit-content',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	WebkitBackgroundClip: 'text !important',
	WebkitTextFillColor: 'transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		height: '20px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.chart.purple.bolder', P200)} !important`,
	},
	marginLeft: token('space.050', '4px'),
	gap: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& span:first-child': {
		height: '16px',
		width: '16px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		svg: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			color: `${token('color.chart.purple.bolder.hovered', P300)} !important`,
		},
		background: `linear-gradient( 80deg, ${token(
			'color.chart.purple.bolder.hovered',
			P300,
		)}, ${token('color.chart.blue.bolder.hovered', B300)} )`,
		WebkitBackgroundClip: 'text',
		WebkitTextFillColor: 'transparent',
	},
});

type GradientSubtleLinkButtonProps = PropsWithChildren<{
	href?: string;
	onClick?: () => void;
	target?: string;
}>;

export const GradientSubtleLinkButton: FC<GradientSubtleLinkButtonProps> = ({
	href,
	onClick,
	target,
	children,
}: GradientSubtleLinkButtonProps) => {
	return (
		<LinkWrapper href={href} onClick={onClick} target={target}>
			<PremiumIcon size="small" label="" />
			{children}
		</LinkWrapper>
	);
};
