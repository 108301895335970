import { useCallback, useMemo } from 'react';

import { GATEWAY_URL, useCommerceFetch } from '@atlassian/commerce-environment';
import { type FetchServiceResult, useFetchService } from '@atlassian/commerce-service-hook';
import { ProbabilityOfBug, Service, useTaskTracker } from '@atlassian/commerce-telemetry';

import { isException, isSuccessful, type Result } from '../../common/utils/resultful';

const BILLING_API_GATEWAY_URL = `${GATEWAY_URL}/bag`;

/**
 * General options for calling Commerce APIs
 */
export type CommerceApiOptions = {
	/**
	 * The implementation of the fetch API to use
	 */
	fetch: typeof fetch;

	/**
	 * For backend use only: the user to act on behalf of
	 */
	aaid?: string;
};

export const COMMERCE_BACKEND_HAMS = 'HAMS' as const;
export const COMMERCE_BACKEND_CCP = 'CCP' as const;

/**
 * Information about the commerce backend system used to handle payment,
 * subscriptions, etc for a product*site.
 */
export type CommerceRolloutInformation =
	| {
			commerceBackend: 'HAMS';
			/**
			 * Information specific to (product*site)s using the HAMS commerce
			 * backend. Currently empty.
			 */
			hams: Record<string, never>;
	  }
	| {
			commerceBackend: 'CCP';
			/**
			 * Information specific to (product*site)s using the CCP commerce
			 * backend (e.g. transaction account ID, entitlement ID). Currently
			 * empty.
			 */
			ccp: Record<string, never>;
	  };

export type FetchCommerceRolloutInformationParams = {
	cloudId: string;
	productKey: string;
};

export type FetchCommerceRolloutInformationResponse = {
	service: 'HAMS' | 'CCP';
};

/**
 * Fetch information about the commerce backends used by a product*site
 */
export const fetchCommerceRolloutInformation = async (
	params: FetchCommerceRolloutInformationParams,
	{ fetch }: CommerceApiOptions,
): Promise<Result<CommerceRolloutInformation, unknown, unknown>> => {
	try {
		const response = await fetch(
			`${BILLING_API_GATEWAY_URL}/rollout/service?cloudId=${encodeURIComponent(params.cloudId)}`,
		);

		if (response.status !== 200) {
			return {
				payload: undefined,
				error: `Failed to fetch commerce rollout information: ${response.statusText}`,
				exception: undefined,
			};
		}

		const service = ((await response.json()) as FetchCommerceRolloutInformationResponse).service;

		return {
			payload:
				service === 'CCP'
					? {
							commerceBackend: service,
							ccp: {},
						}
					: { commerceBackend: service, hams: {} },
			error: undefined,
			exception: undefined,
		};
	} catch (exception) {
		return {
			payload: undefined,
			error: undefined,
			exception,
		};
	}
};

/**
 * Hook to fetch information about the commerce and provisioning backends used
 * by a site
 */
export const useFetchCommerceRolloutInformation = (
	params: FetchCommerceRolloutInformationParams,
	options?: CommerceApiOptions,
): FetchServiceResult<CommerceRolloutInformation, unknown> => {
	const fetch = useCommerceFetch();
	const effectiveOptions = useMemo(
		() => ({
			fetch,
			...options,
		}),
		[fetch, options],
	);
	const startTask = useTaskTracker({
		action: 'fetched',
		actionSubject: 'commerceRolloutInformation',
		source: 'unknown',
	});

	return useFetchService(
		useCallback(async () => {
			const taskResult = await startTask(async () => {
				const result = await fetchCommerceRolloutInformation(params, effectiveOptions);

				if (isSuccessful(result)) {
					return {
						succeed: {
							payload: result.payload,
						},
					};
				} else if (isException(result)) {
					// fetch can sometimes throw, but most likely this is a bug
					return {
						fail: {
							payload: result.exception,
							loggablePayload: result.exception,
							responsibleService: Service.COMMERCE_LIBRARIES,
							probabilityOfBug: ProbabilityOfBug.GUARANTEED,
						},
					};
				}

				return {
					// Failed response from server.
					fail: {
						payload: result.error,
						responsibleService: Service.BILLING_API_GATEWAY,
						// TODO: Review HTTP codes to differentiate between bug and normal errors
						probabilityOfBug: ProbabilityOfBug.MAYBE,
					},
				};
			});

			if (taskResult.succeed !== undefined) {
				return taskResult.succeed.payload;
			}
			throw taskResult.fail.payload;
		}, [startTask, params, effectiveOptions]),
	);
};
