import memoizeOne from 'memoize-one';
import { useEffect, useState } from 'react';

import type { COMMERCE_BACKEND_CCP, COMMERCE_BACKEND_HAMS } from '@atlassian/commerce-ui/rollout';
import { fetchCommerceRolloutInformation } from '@atlassian/commerce-ui/rollout';
import { isException, isSuccessful } from '@atlassian/commerce-resultful';

import { cfetch } from '@confluence/network';
import { useSessionData } from '@confluence/session-data';

export const CONFLUENCE_PRODUCT_KEY = 'confluence.ondemand';

export type CommerceBackendName = typeof COMMERCE_BACKEND_HAMS | typeof COMMERCE_BACKEND_CCP | null;

export type UseCommerceRolloutInformationResponse = {
	commerceBackendName: CommerceBackendName;
	loading: boolean;
	error: Error | null;
};

const memoizedFetchCommerceRolloutInformation = memoizeOne(async (cloudId: string) => {
	const rolloutInfoResult = await fetchCommerceRolloutInformation(
		{ cloudId, productKey: CONFLUENCE_PRODUCT_KEY },
		{ fetch: cfetch },
	);
	return rolloutInfoResult;
});

export const useCommerceRolloutInformation = (
	skip: boolean = false,
): UseCommerceRolloutInformationResponse => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);
	const [commerceBackendName, setCommerceBackendName] = useState<CommerceBackendName>(null);

	const { cloudId } = useSessionData();
	if (!cloudId) {
		skip = true;
	}

	useEffect(() => {
		if (skip) {
			return;
		}
		const fetchCommerceRolloutInformation = async () => {
			try {
				const rolloutInfoResult = await memoizedFetchCommerceRolloutInformation(cloudId);
				if (isSuccessful(rolloutInfoResult)) {
					setCommerceBackendName(rolloutInfoResult.payload.commerceBackend);
				} else {
					const error = isException(rolloutInfoResult)
						? rolloutInfoResult.exception
						: rolloutInfoResult.error;
					setError(error as Error);
				}
			} catch (error) {
				error.message = `useCommerceBackendName: ${error.message}`;
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		void fetchCommerceRolloutInformation();
	}, [cloudId, skip]);

	return {
		commerceBackendName: skip ? null : commerceBackendName,
		loading: skip ? false : loading,
		error: skip ? null : error,
	};
};
