import gql from 'graphql-tag';

export const waitForEditionChangeQuery = gql`
	query waitForEditionChangeQuery {
		tenant {
			editions {
				edition
			}
		}
	}
`;
