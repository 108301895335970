import gql from 'graphql-tag';

import { CcpEntitlementFragment } from './CcpEntitlementFragment.fragment.js';

export const CcpEntitlementQuery = gql`
	query CcpEntitlementQuery {
		...CcpEntitlementFragment
	}

	${CcpEntitlementFragment}
`;
